import React, { useRef, useState } from 'react'
import { Button, message, Spin, Alert } from 'antd'
import { UploadFile } from 'antd/lib/upload/interface'
import { GlobalCatalogUploadModal } from './GlobalCatalogUploadModal'
import { parseErrorMsg } from '../../../utilities/helpers'
import { CompaniesModels } from '@getgreenline/companies'
import { axios } from '@getgreenline/homi-shared'
import { GlobalProductsHeader } from './GlobalCatalogHeader'
import Endpoints from '../../../constants/Endpoints'
import './GlobalCatalogImport.scss'

const provinceOptions = [
  {
    name: 'Alberta',
    value: CompaniesModels.Province.AB,
  },
  {
    name: 'British Columbia',
    value: CompaniesModels.Province.BC,
  },
  {
    name: 'Manitoba',
    value: CompaniesModels.Province.MB,
  },
  {
    name: 'Ontario & all other provinces',
    value: CompaniesModels.Province.ON,
  },
]

export function GlobalCatalogImport() {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [fileName, setFileName] = useState<string>()
  const [error, setError] = useState<string>()

  const uploadToGlobalProducts = useRef(false)

  const handleModalClose = () => {
    setModalIsOpen(false)
  }

  const parseDroppedFile = (file: UploadFile) => {
    const { name, type, originFileObj } = file
    const fileExtension = name.split('.').pop()

    return {
      originFileObj,
      fullFileName: name,
      fileExtension,
      contentType: type,
    }
  }

  const handleFileDrop = async (selectedProvince: CompaniesModels.Province, file: UploadFile) => {
    handleModalClose()
    setError(undefined)

    try {
      const { originFileObj, fullFileName, fileExtension, contentType } = parseDroppedFile(file)
      if (!fileExtension || fileExtension === fullFileName) {
        message.error('Invalid file extension')
        return
      }

      setIsUploading(true)

      const fileExtensionLowerCase = fileExtension.toLowerCase() === 'csv' ? 'csv' : 'xlsx'

      const presignedUrlResponse = await axios.get(
        Endpoints.GET_GLOBAL_PRODUCTS_UPLOAD_SIGNED_URL(selectedProvince, fileExtensionLowerCase),
      )
      const { signedUrl } = presignedUrlResponse.data

      await axios.put(signedUrl, originFileObj, {
        transformRequest: (data, headers) => {
          delete headers.common['Authorization']
          return data
        },
      })

      setFileName(fileName)

      message.success('Global Catalog uploaded')
    } catch (error) {
      message.error(parseErrorMsg(error) || 'An error occurred while uploading the file', 10)
    } finally {
      setIsUploading(false)
    }
  }

  return (
    <>
      <GlobalProductsHeader />
      <div className='global-catalog-update'>
        {modalIsOpen && (
          <GlobalCatalogUploadModal
            provinces={provinceOptions}
            onModalClose={handleModalClose}
            onFileDrop={handleFileDrop}
          />
        )}

        {error && (
          <div className='alert-error'>
            <Alert
              type='error'
              message='Error'
              description={error}
              onClose={() => setError(undefined)}
              showIcon
              closable
            />
          </div>
        )}

        {isUploading ? (
          <div className='center-div'>
            <Spin />
          </div>
        ) : (
          <div className='center-div'>
            <Button
              size='large'
              icon='import'
              onClick={() => {
                uploadToGlobalProducts.current = false
                setModalIsOpen(true)
              }}
            >
              Import change set
            </Button>
          </div>
        )}
      </div>
    </>
  )
}
